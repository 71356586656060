import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../assets/icon.c829beff.png';

function App() {
  return (
    <Container fluid className="mt-5 mb-5 ml-2" >
      <Row>
        <Col className="mt-5" sm md={{ span: 2, offset: 3 }} style={{display: 'flex', alignItems: 'center'}}>
          <img
            width={300}
            src={logo}
            style={{margin:'auto'}}
            alt="ícone logo coletivxs"
          />
        </Col>
        <Col className="mt-5" sm md={{ span: 3 }} style={{display: 'flex', alignItems: 'center'}}>
          <h1 className="text-center" style={{fontSize: 48, fontWeight: 'bold'}}>por um mundo que não deixa ninguém pra trás</h1>
        </Col>
      </Row>
    </Container >
  );
}

export default App;
