import logo from '../assets/logo-menu.754ca6ff.png';
import insta from '../assets/icone-insta.46f3d5f4.png';
import youtube from '../assets/icone-youtube.7a7c084b.png';
import { Container, Nav, Navbar } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <Container>
      <Navbar collapseOnSelect style={{ backgroundColor: '#FFFFFF', opacity: 0.75 }} expand="lg" fixed="top" >
        <Navbar.Brand className="ml-5 mr-5" href="/"><img
          src={logo}
          width="140"
          height="30"
          className="d-inline-block align-top"
          alt="coletivxs logo"
        /></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse className="ml-5 mr-5" id="responsive-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/"><h3 style={{ 'color': '#000', fontWeight: 'bold' }} className="mr-5" >home</h3></Nav.Link>
            <Nav.Link href="/projects"><h3 style={{ 'color': '#fd9900', fontWeight: 'bold' }} className="mr-5" >projetos</h3></Nav.Link>
            <Nav.Link href="https://www.instagram.com/coletivxs/?hl=pt-br" className="mr-5" target="_blank"><img
              src={insta}
              height="30"
              className="d-inline-block align-top"
              alt="instagram"
            /></Nav.Link>
            <Nav.Link href="https://www.youtube.com/channel/UClJL6xnGGA1SJR9YzxZb2dw" target="_blank"><img
              src={youtube}
              height="29"
              className="d-inline-block align-top"
              alt="youtube"
            /></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
}

export default App;
