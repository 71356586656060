import React from 'react';
import './styles/index.css';
import MenuProjects from './pages/MenuProjects';
import Projects from './pages/Projects';
import ProjectsImages from './pages/ProjectsImages';
import Magin from './pages/Magin';
import reportWebVitals from './reportWebVitals';

function App() {
  return (
  <>
    <MenuProjects />
    <Magin/>
    <Projects/>
    <ProjectsImages/>
    <Magin/>
  </>
)};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;
