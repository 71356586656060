import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import processo from '../assets/processo.1ab7c87e.png';

function App() {
  return (
    <Container className="mt-5 mb-5" fluid id="about">
      <Row>
        <Col sm md={{ span: 4, offset: 1 }}>
          <Row className="mt-5">
            <Col sm style={{ textAlign: 'center' }}><h1 style={{ fontWeight: 'bold', color:'#cc3300' }}>sobre</h1></Col>
          </Row>
          <Row>
            <Col sm style={{ textAlign: 'center' }}><p style={{ fontSize: 22 }}>O <a style={{ fontWeight: 'bold' }}>coletivxs</a> se define como um grupo <a style={{ fontWeight: 'bold' }}>plural e horizontal</a> que <a style={{ fontWeight: 'bold' }}>estuda, dialoga e executa</a> intervenções em prol da <a style={{ fontWeight: 'bold' }}>inclusão educacional e social</a>. A Agenda 2030 e os 17 Objetivos de Desenvolvimento Sustentável são nossas <a style={{ fontWeight: 'bold' }}>metas. Fortalecer relações é nossa estratégia.</a><br></br>Realizamos pesquisas, projetos e assessorias que apostam na construção coletiva como estratégia potente para <a style={{ fontWeight: 'bold' }}>não deixar ninguém para trás</a>.<br></br>Somos formados por profissionais de diferentes áreas e nossa composição é reflexo da visão que temos: é no <a style={{ fontWeight: 'bold' }}>compartilhamento de informações e na convivência</a> entre pessoas singulares que se torna possível desenvolver genuinamente projetos <a style={{ fontWeight: 'bold' }}>colaborativos e inclusivos</a> para uma sociedade menos desigual e mais diversa.</p></Col>
          </Row>
        </Col>
        <Col sm md={{ span: 4, offset: 1 }}>
          <Row className="mt-5">
            <Col style={{ textAlign: 'center' }}><h1 style={{ fontWeight: 'bold', color:'#cc3300' }}>porque coletivxs?</h1></Col>
          </Row>
          <Row>
            <Col style={{ textAlign: 'center' }}><p style={{ fontSize: 22 }}>A identidade visual do <a style={{ fontWeight: 'bold' }}>coletivxs®️</a> é formada pela união entre símbolos e ideias numa representação gráfica que congrega diversos elementos.<br></br> Inspirados pelo ideograma japonês <a style={{ fontWeight: 'bold' }}>hito [人]</a>. , que significa pessoa, pessoas ou humanidade, buscamos dar centralidade à razão de nossa atuação, utilizando como base do nosso logotipo o <a style={{ fontWeight: 'bold' }}>ser humano.</a><br></br>Adicionamos ao 人 outro elemento para fazer alusão ao X. Assim, a incógnita mais famosa da matemática nos auxiliou na representação de que <a style={{ fontWeight: 'bold' }}>somos muitos, múltiplos e variáveis</a>. <br></br> A marca tem como características ser <a style={{ fontWeight: 'bold' }}>plural, acessível e adaptável</a>. Sua simbologia e aplicabilidade em diferentes peças são as qualidades perfeitas para representar o <a style={{ fontWeight: 'bold' }}>coletivxs</a>, uma organização horizontal que <a style={{ fontWeight: 'bold' }}>aposta na humanidade e no potencial de cada pessoa</a>.</p></Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            width={window.innerWidth <= 760 ? '90%' : '40%'}
            height={'auto'}
            className="mr-3"
            src={processo}
            style={{ objectFit: 'contain' }}
            alt="figura do processo de criação do logo do Coletivxs, iniciando com um traço, em seguida um ideograma japonês que significa pessoa, posteriormente a inclusão de um traço em cima fazendo alusão a letra “x”. Depois temos essa figura um pouco mais robusta até que finalmente a versão final do logo"
          />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
