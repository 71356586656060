import { Container, Row, Col, Stack  } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import liliane from '../assets/liliane.png';
import milson from '../assets/milson.png';
import alexandre from '../assets/alexandre.png';
import patricia from '../assets/patricia.png';
import raquel from '../assets/raquel.png';
import sandra from '../assets/sandra.png';
import rosangela from '../assets/rosangela.png';
import katia from '../assets/katia.png';

function App() {
  return (
    <Container fluid className="mt-5" id="participants">
      <Row className="mr-2 ml-2" style={{display: 'flex', justifyContent: 'center'}}>
        <Col sm md={{ span: 3 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={liliane}
              alt="Liliane Garcez, idealizadora e articuladora. Mulher branca, cabelos grisalhos, sorrindo, vestindo uma blusa azul com flores bracas."
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>liliane garcez</h5>
              <h6>idealizadora & articuladora</h6>
            </Stack>
          </Stack>
        </Col>
        <Col sm md={{ span: 3 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={milson}
              alt="Milson Almeida, educador. Homem branco, de barba e bigode pretos, sorrindo com a mão apoiada no queixo."
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>milson almeida</h5>
              <h6>educador</h6>
            </Stack>
          </Stack >
        </Col>
        
        <Col sm md={{ span: 3 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={raquel}
              alt="Raquel Monteiro, fonoaudióloga. Mulher branca, cabelo castanho na altura do ombro, sorrindo com uma blusa preta."
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>raquel monteiro</h5>
              <h6>fonoaudióloga</h6>
            </Stack>
          </Stack >
        </Col>
      </Row>
      <Row className="mr-2 ml-2" style={{display: 'flex', justifyContent: 'center'}}>
        <Col sm md={{ span: 3, offset: 1 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={alexandre}
              alt="Alexandre Moreira, educomunicador. Homem branco, cabelos e barbas pretos, falando e gesticulando."
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>alexandre moreira</h5>
              <h6>educomunicador</h6>
            </Stack>
          </Stack >
        </Col>
        <Col sm md={{ span: 3 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={patricia}
              alt="Patricia David, educadora. Mulher branca, cabelos castanhos curtos, sorrindo. "
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>patricia david</h5>
              <h6>educadora</h6>
            </Stack>
          </Stack >
        </Col>
      </Row>
      <Row className="mr-2 ml-2" style={{display: 'flex', justifyContent: 'center'}}>
      <Col sm md={{ span: 3 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={sandra}
              alt="Sandra Batistão, educadora. Mulher branca, cabelos castanhos, cacheados e curtos, sorrindo com uma camisa vermelha."
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>sandra batistão</h5>
              <h6>educadora</h6>
            </Stack>
          </Stack >
        </Col>
        <Col sm md={{ span: 3 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={rosangela}
              alt="Rosangela Jacob, educadora. Mulher branca, cabelos cacheados e longos, sorrindo com um lenço colorido no pescoço."
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>rosangela jacob</h5>
              <h6>educadora</h6>
            </Stack>
          </Stack >
        </Col>
        <Col sm md={{ span: 3 }} className="mt-5">
          <Stack  style={{ display: 'flex', 'alignItems': 'center' }}>
            <img
              width={140}
              height={140}
              className="mr-3"
              src={katia}
              alt="Katia Cibas, educadora. Mulher branca, de 47 anos, cabelos castanhos e longos, sorrindo em fundo amarelo. "
            />
            <Stack>
              <h5 style={{ fontWeight: 'bold', color:"#366666" }}>katia cibas</h5>
              <h6>educadora</h6>
            </Stack>
          </Stack >
        </Col>
      </Row>
    </Container>
  );
}

export default App;
