import { Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';


function App() {
  return (
    <Container className="mt-5">
      <h1 style={{visibility:'hidden'}}>A</h1>
    </Container>
  );
}

export default App;
