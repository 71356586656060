import { Container, Row, Image, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import carrossel1 from '../assets/miniaturas_final-04.png';
import carrossel2 from '../assets/miniaturas_final-05.png';
import carrossel3 from '../assets/miniaturas_final-06.png';

function App() {
  return (
    <Container className="mt-5 mb-5" fluid>
      <Row>
        <Col sm md={{ span: 4 }} className="mt-5">
          <a href="https://www.instagram.com/p/CB314aUF3c_/?utm_source=ig_web_copy_link" target="_blank">
            <img
              width={572}
              className="d-block w-100"
              src={carrossel1}
              alt="Third slide"
            />
          </a>
        </Col>
        <Col sm md={{ span: 4 }} className="mt-5">
          <a href="https://www.instagram.com/p/CHgfxR_ndQC/?utm_source=ig_web_copy_link" target="_blank">
            <img
              width={572}
              className="d-block w-100"
              src={carrossel2}
              alt="Third slide"
            />
          </a>
        </Col>
        <Col sm md={{ span: 4 }} className="mt-5">
          <a href="https://www.instagram.com/p/CDrNJcRHFff/?utm_source=ig_web_copy_link" target="_blank">
            <img
              width={572}
              className="d-block w-100"
              src={carrossel3}
              alt="Third slide"
            />
          </a>
        </Col>
      </Row>
    </Container>
  );
}

export default App;
