import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import insta from '../assets/icone-insta.46f3d5f4.png';
import email from '../assets/icone-email.da8a9a4d.png';

function App() {
  return (
    <Container fluid className="mt-5" id="contact">
      <Row>
        <Col className="text-center"><h2 style={{ fontWeight: 'bold', color:"#996636" }}>contato</h2></Col>
      </Row>
      <Row className="mt-4">
        <Col className="text-center"><a href="mailto:contato@coletivxs.com" target="_blank"><h5 style={{ fontWeight: 'bold', color: 'black' }}><img
          src={email}
          height="18"
          alt="símbolo de envelope de carta"
          className="mr-2"
        />contato@coletivxs.com</h5></a></Col>
      </Row>
      <Row className="mt-1">
        <Col className="text-center ml-2">
          <a href="https://www.instagram.com/coletivxs/?hl=pt-br" target="_blank"><h5 style={{ fontWeight: 'bold', color: 'black' }}><img
            src={insta}
            height="25"
            width="25"
            alt="logotipo do instagram"
            className="mr-2"
          />instagram.com/coletivxs</h5></a></Col>
      </Row>
      <Row className="mt-4 mb-3">
        <Col className="text-center"><h7 style={{ fontWeight: 'bold' }}>Copyright © 2021 COLETIVXS. Todos os Direitos Reservados</h7></Col>
      </Row>
    </Container>
  );
}

export default App;
