
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import Menu from './pages/Menu';
import Logo from './pages/Logo';
import Content from './pages/Content';
import Participants from './pages/Participants';
import Contact from './pages/Contact';
import reportWebVitals from './reportWebVitals';

function App() {
  return (
    <>
      <Menu />
      <Logo />
      <Content />
      <Participants />
      <Contact />
    </>
  )
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export default App;