import React from "react";
import { Route, Routes } from "react-router-dom";
import Main from "./main";
import Projects from "./projects";

const App = () => {
    return (
        <Routes>
            <Route exact={true} path="/" element={<Main />} />
            <Route path="/projects" element={<Projects />} />
        </Routes>
    );
};

export default App;
