import logo from "../assets/logo-menu.754ca6ff.png";
import insta from "../assets/icone-insta.46f3d5f4.png";
import youtube from "../assets/icone-youtube.7a7c084b.png";
import { Nav, Navbar, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
    return (
        <Navbar
            collapseOnSelect
            style={{ backgroundColor: "#FFFFFF", opacity: 0.75 }}
            expand="lg"
            fixed="top"
        >
            <Container fluid>
                <Navbar.Brand className="ml-5">
                    <img
                        src={logo}
                        width="140"
                        height="30"
                        style={{ marginLeft: 16 }}
                        className="d-inline-block align-top "
                        alt="coletivxs logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav
                        className="me-auto my-2 my-lg-1"
                        style={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Nav.Link href="#about" className="ml-2 mr-5">
                            <h3 style={{ color: "#000", fontWeight: "bold" }}>
                                sobre
                            </h3>
                        </Nav.Link>
                        <Nav.Link href="#participants" className="ml-2 mr-5">
                            <h3 style={{ color: "#000", fontWeight: "bold" }}>
                                participantxs
                            </h3>
                        </Nav.Link>
                        <Nav.Link href="/projects" className="ml-2 mr-5">
                            <h3 style={{ color: "#000", fontWeight: "bold" }}>
                                projetos
                            </h3>
                        </Nav.Link>
                        <Nav.Link href="#contact" className="ml-2 mr-5">
                            <h3 style={{ color: "#000", fontWeight: "bold" }}>
                                contato
                            </h3>
                        </Nav.Link>
                    </Nav>
                    <Nav className="mx-5 d-inline-block">
                        <a
                            href="https://www.instagram.com/coletivxs/?hl=pt-br"
                            style={{ width: "50px", marginRight: 16 }}
                            target="_blank"
                        >
                            <img
                                src={insta}
                                height={window.innerWidth <= 760 ? 25 : 33}
                                className="d-inline-block align-top"
                                alt="instagram"
                            />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UClJL6xnGGA1SJR9YzxZb2dw"
                            style={{ width: "50px" }}
                            target="_blank"
                        >
                            <img
                                src={youtube}
                                height={window.innerWidth <= 760 ? 24 : 32}
                                className="d-inline-block align-top ml-4"
                                alt="youtube"
                            />
                        </a>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default App;
