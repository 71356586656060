import { Carousel, Container } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import carrossel1 from '../assets/carrossel-01.png';
import carrossel2 from '../assets/carrossel-02.png';
import carrossel3 from '../assets/porondeandas_final_carrossel-03.png';

function App() {
  return (
    <Container className="mt-5" fluid>
      <Carousel>
        <Carousel.Item>
          <img
            height={"100%"}
            className="d-block w-100"
            src={carrossel2}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height={"100%"}
            className="d-block w-100"
            src={carrossel1}
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            height={"100%"}
            className="d-block w-100"
            src={carrossel3}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </Container>
  );
}

export default App;
